.content {
  padding: 20px;
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  width: 100%;
}
