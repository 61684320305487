.dashboarrd-breadcrumb {
  display: flex;
  align-items: center;
  height: 3rem;
  background-color: #fff;
  border-bottom: 1px solid #e9ecef;
  font-size: 0.875rem;
  font-weight: 600;
  color: #212529;
  white-space: nowrap;
  margin-bottom: 10px;
}
