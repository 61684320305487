.PageWithSideBar {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.PageWithSideBarContent {
  margin-left: 20px;
  flex: 1;
  padding-right: 10px;
}
