.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  background-color: black;
  height: 56px;
  color: white;
}

.app-name {
  padding: 10px;
  flex: 1;
}

.user-email {
  padding: 10px;
}

.login-logout-button {
  padding: 10px;
  width: 100px;
  color: white;
  background-color: black;
}

.vallourec-logo {
  width: 110px;
  height: 35px;
  padding: 1px;
  border-radius: 5px;
}

.admin-of {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
  padding-top: 0;
  margin-top: 4px;
}

.env-show {
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
